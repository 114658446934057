import React, { Component } from 'react';
import Headroom from 'react-headroom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, Row, css, styled, withTheme, Col, up } from '@smooth-ui/core-sc';
import HeaderLogo from './images/header-logo@2x.png';

const CustomHeadroom = styled(Headroom)`
  .headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  .headroom--unfixed {
    position: relative;
    transform: translateY(0);
  }
  .headroom--scrolled {
    transition: transform 200ms ease-in-out;
  }
  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
  }
  .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
  }
`;

const HeaderWrapper = styled.div`
  background-color: #1c355e;
  padding: 10px 0;

  width: 100%;
`;

const LogoContainer = styled.div`
  align-items: bottom;
  display: flex;
  height: 60px;
  justify-content: left;
  max-width: 100%;
  width: 100%;
  padding-left: 10px;
  img {
    height: 55px;
    height: auto;
    width: 80px;
  }
`;

const HeaderRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 5px;
  width: 100%;

  ${up(
    'lg',
    css`
      padding-top: 20px;
      flex-direction: column;
    `
  )}
`;

class Header extends Component {
  render() {
    return (
      <header className="site-header">
        {/* Leave the above <header /> here, necessary to contain Headroom */}
        {/* Otherwise Headroom will wrap all sibling elements */}
        <CustomHeadroom disableInlineStyles downTolerance={10}>
          <HeaderWrapper className="header-wrapper">
            <Grid>
              <Row>
                <Col lg={3}>
                  <LogoContainer>
                    <Link to="/">
                      <img src={HeaderLogo} alt="Patties Food Group" />
                    </Link>
                  </LogoContainer>
                </Col>
                <Col>
                  <HeaderRightContainer>
                    {this.props.children}
                  </HeaderRightContainer>
                </Col>
              </Row>
            </Grid>
          </HeaderWrapper>
        </CustomHeadroom>
      </header>
    );
  }
}

Header.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object,
};

Header.defaultProps = {
  children: undefined,
  theme: undefined,
};

export default withTheme(Header);
